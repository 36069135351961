<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>礼品信息</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form
        class="form-container"
        ref="form"
        :model="form"
        label-width="140px"
      >
        <el-form-item
          label="序号"
          :error="$v.form.sorder.$error ? '请输入序号' : ''"
        >
          <el-input
            v-model.number="form.sorder"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商品"
          :error="$v.form.productid.$error ? '请选择所属商品' : ''"
        >

          <el-select
            v-model="form.productid"
            filterable
            allow-create
            default-first-option
            placeholder="请选择商品"
          >
            <el-option
              v-for="item in classify"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item
          label="首页图"
          :error="$v.form.giftimg.$error ? '请上传首页图' : ''"
        >
          <image-upload
            @upload="imageUpload"
            :image="form.giftimg"
          />
        </el-form-item>

        <el-form-item
          label="礼品有效期"
          :error="$v.form.entdate.$error ? '请输入礼品有效期' : ''"
        >
          <el-date-picker
            v-model="form.entdate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="礼品价值"
          :error="$v.form.giftprice.$error ? '请输入礼品价值' : ''"
        >
          <el-input-number
            v-model="form.giftprice"
            :precision="2"
            :step="0.01"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="礼品库存"
          :error="$v.form.giftstock.$error ? '请输入库存' : ''"
        >
          <el-input v-model.number="form.giftstock"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          style="width:100%"
          label="描述"
          :error="$v.form.contensts.$error ? '请输入描述' : ''"
        >
          <tinymce v-model="form.contensts" />

        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="save"
          >保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import FileUpload from "@/components/fileUpload";
import { editRequest, getdatilRequest } from "@/api/zrgiftinfo";
import { getSelectRequest } from "@/api/zrproduct";
import { getSelectshopRequest } from "@/api/zrshop";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "Articletypeedit",
  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
        productid: ""
      },
      productidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0
    };
  },
  components: {
    "image-upload": ImageUpload,
    "file-upload": FileUpload,
    tinymce: Tinymce
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.typeId = this.$route.query["typeId"];
    this.getSelect();
    this.getSelectshop();
    this.getDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    imageUpload(url) {
      this.form.giftimg = url;
    },
    getSelect() {
      getSelectRequest().then(res => {
        console.log(res);
        this.classify = res.data;
      });
    },
    getSelectshop() {
      getSelectshopRequest().then(res => {
        this.shopdata = res.data;
      });
    },
    getDetail() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        if (this.form.id == 0) {
          res.data.productid = "";
          res.data.shopid = "";
        }
        this.form = res.data;
      });
    },

    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));
        params.giftimg = utils.removeUrl(params.giftimg);
        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      sorder: {
        required
      },
      contensts: {
        required
      },
      giftprice: {
        required
      },

      giftstock: {
        required
      },
      giftimg: {
        required
      },
      states: {
        required
      },
      productid: { required },
      entdate: { required }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
